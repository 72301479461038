import React, { useContext } from "react";
import "../../styles/PrendreRendezVous.css";
import { useState } from "react";
import "react-day-picker/dist/style.css";

import { MyContext } from "../../utils/MyContext";

// import axios from "axios";
import API_URL from "../../config/variables-prod";
import axios from "axios";

import PrendreRendezVousForm from "./PrendreRendezVousForm";
import PrendreRendezVousFormHandleSubmit from "./PrendreRendezVousFormHandleSubmit";

const PrendreRendezVous = (props) => {
  const [formData, setFormData] = useState({
    lastname: "",
    firstname: "",
    phoneNumber: "",
    appointmentDayObject: new Date(),
    appointmentDay: "",
    appointmentHourBegin: "",
    appointmentTimeBeginISO: "",
    appointmentTimeEndISO: "",
    appointmentTimestampBegin: 0,
    appointmentTimestampEnd: 0,
    appointmentTimeBeginFR: "",
    appointmentTimeEndFR: "",
    durationMinutes: 60,
    selectedHourEnd: "",
    appointmentsAlreadyTaken: [],
    today: new Date().toLocaleDateString().replaceAll("/", "-"),
    prestation: {},
  });

  const [alreadyClickOnSubmitFormButton, setAlreadyClickOnSubmitFormButton] =
    useState(false);

  const [successfullySubmitForm, setSuccessfullySubmitForm] = useState(false);

  const { navbarHeight, ScrollToSection } = useContext(MyContext);

  const [appointmentsAlreadyTaken, setAppointmentsAlreadyTaken] = useState([]);

  const [selectedDay, setSelectedDay] = useState(false);

  const [rateLimitOK, setRateLimitOK] = useState(true);

  //In case the prestation is being choosen to another one - we should update the duration
  // useEffect(() => {

  // setFormData((prevFormData) => ({
  //   ...prevFormData,
  //   appointmentDayObject: new Date(),
  //   appointmentDay: appointmentDay,
  //   durationMinutes: props.prestation.durationMinutes,
  // }));

  //   axios
  //     .get(API_URL + "/api/v1/appointments", { params: { appointmentDay } })
  //     .then((res) => {
  //       console.log("res", res);
  //       // formData.appointmentsAlreadyTaken = res.data.appointments.Items;
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,

  //         appointmentsAlreadyTaken: res.data.appointments.Items,
  //       }));
  //     })
  //     .catch((error) => {
  //       console.log("Error: ", error);
  //     });
  // }, []);

  // useEffect(() => {
  //   if (props.prestation && props.prestation.value !== "") {
  //     // setFormData((prevFormData) => ({
  //     //   ...prevFormData,
  //     //   durationMinutes: props.prestation.durationMinutes,
  //     // }));

  //     // const appointmentDay = new Date()
  //     //   .toLocaleDateString()
  //     //   .replaceAll("/", "-");

  //     // axios
  //     //   .get(API_URL + "/api/v1/appointments", { params: { appointmentDay } })
  //     //   .then((res) => {
  //     //     console.log("res", res);
  //     //     // formData.appointmentsAlreadyTaken = res.data.appointments.Items;
  //     //     setFormData((prevFormData) => ({
  //     //       ...prevFormData,

  //     //       appointmentsAlreadyTaken: res.data.appointments.Items,
  //     //     }));
  //     //   })
  //     //   .catch((error) => {
  //     //     console.log("Error: ", error);
  //     //   });

  //     console.log(
  //       "aa props.prestation durationMinutes",
  //       formData.durationMinutes
  //     );
  //     //handleChange(new Date(), "appointmentDayObject");

  //     console.log(
  //       "bb props.prestation durationMinutes",
  //       formData.durationMinutes
  //     );
  //   }
  // }, [props.prestation]);

  // useEffect(() => {
  //   const appointmentDay = new Date().toLocaleDateString().replaceAll("/", "-");

  //   console.log("useeffect appointmentDay", appointmentDay);

  //   axios
  //     .get(API_URL + "/api/v1/appointments", { params: { appointmentDay } })
  //     .then((res) => {
  //       console.log("res", res);
  //       // formData.appointmentsAlreadyTaken = res.data.appointments.Items;
  //       // setFormData((prevFormData) => ({
  //       //   ...prevFormData,

  //       //   appointmentsAlreadyTaken: res.data.appointments.Items,
  //       // }));
  //       setAppointmentsAlreadyTaken(res.data.appointments.Items);
  //     })
  //     .catch((error) => {
  //       console.log("Error: ", error);
  //     });
  // }, [props.prestation]);

  const handleChange = async (e, field, value = "default_value") => {
    //handle the date input
    if (field === "appointmentDayObject") {
      // console.log("selectedDayss", selectedDay);
      setSelectedDay(true);

      //Get the appointments for the selected day
      // console.log("debug e", e);
      const appointmentDay = e.toLocaleDateString().replaceAll("/", "-");

      //  console.log("handleChange appointmentDay", appointmentDay);

      setFormData((prevFormData) => ({
        ...prevFormData,
        appointmentDayObject: e,
        appointmentDay: appointmentDay,
        durationMinutes: props.prestation.durationMinutes,
        prestation: props.prestation,
      }));

      axios
        .get(API_URL + "/api/v1/appointments", { params: { appointmentDay } })
        .then((res) => {
          // console.log("res", res);
          // formData.appointmentsAlreadyTaken = res.data.appointments.Items;
          // setFormData((prevFormData) => ({
          //   ...prevFormData,

          //   appointmentsAlreadyTaken: res.data.appointments.Items,
          // }));

          setAppointmentsAlreadyTaken(res.data.appointments.Items);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });

      // ScrollToSection("label-choose-day", navbarHeight);

      // ScrollToSection("before-label-appointmentDay", navbarHeight);
      ScrollToSection("label-choose-day", navbarHeight);
    } else if (field === "appointmentHourBegin") {
      setFormData({
        ...formData,
        appointmentHourBegin: value.value,
        selectedHourEnd: value.demi,
      });

      // if (formData.durationMinutes === 30) {
      //   setFormData({
      //     ...formData,
      //     appointmentHourBegin: value.value,
      //     selectedHourEnd: value.demi,
      //   });
      // } else if (formData.durationMinutes === 60) {
      //   setFormData({
      //     ...formData,
      //     appointmentHourBegin: value.value,
      //     selectedHourEnd: value.heure,
      //   });
      // }

      ScrollToSection("label-appointmentHour", navbarHeight);
    }
    // handle the phone number input: Check if the input is a number
    else if (field === "phoneNumber") {
      const inputPhone = e.target.value;
      // const phoneRegex = new RegExp(`^\[0-9+]{0,14}$`);
      const phoneRegex = new RegExp(`^[0-9+]{0,14}$`);

      if (phoneRegex.test(inputPhone)) {
        if (e.target.value.length === 0) {
          setFormData({ ...formData, phoneNumber: "" });
        } else {
          setFormData({ ...formData, phoneNumber: e.target.value });
        }
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    PrendreRendezVousFormHandleSubmit(
      formData,
      setSuccessfullySubmitForm,
      setFormData,
      setAlreadyClickOnSubmitFormButton,
      setRateLimitOK
    );
  };

  // check if the appointment is not already taken
  const checkIfAppointmentIsAlreadyTaken = (appointmentTime) => {
    const appointmentTimeValue = appointmentTime.value;

    const appointmentTimestampBegin =
      convertToDayAndHourTimestamp(appointmentTimeValue);

    const appointmentTimestampEnd = convertToDayAndHourTimestamp(
      appointmentTimeValue,
      30
    );

    // const appointmentAlreadyTaken = formData.appointmentsAlreadyTaken.
    const appointmentAlreadyTaken = appointmentsAlreadyTaken.filter(
      (appointment) => {
        return (
          (appointmentTimestampBegin <= appointment.appointmentTimestampBegin &&
            appointment.appointmentTimestampBegin < appointmentTimestampEnd) ||
          (appointmentTimestampBegin <= appointment.appointmentTimestampEnd &&
            appointment.appointmentTimestampEnd <= appointmentTimestampEnd) ||
          (appointmentTimestampBegin <=
            appointment.appointmentTimestampBegin -
              (formData.durationMinutes - 30) * 60 * 1000 &&
            appointment.appointmentTimestampBegin -
              (formData.durationMinutes - 30) * 60 * 1000 <
              appointmentTimestampEnd)
        );
      }
    );

    return appointmentAlreadyTaken.length > 0;
  };

  // convert the date and hour selected to timestamp
  const convertToDayAndHourTimestamp = (
    appointmentTimeValue,
    durationMinutes = 0
  ) => {
    const mergeDateAndTime = new Date(formData.appointmentDayObject);

    mergeDateAndTime.setHours(
      appointmentTimeValue.slice(0, appointmentTimeValue.indexOf("h"))
    );

    if (appointmentTimeValue.indexOf("h30") > 1) {
      mergeDateAndTime.setMinutes(30);
    } else {
      mergeDateAndTime.setMinutes(0);
    }

    if (durationMinutes === 0) {
      return mergeDateAndTime.getTime();
    } else {
      return mergeDateAndTime.getTime() + (durationMinutes - 1) * 60000;
    }
  };

  return (
    <section id="prendre-rendez-vous">
      {props.prestation ? (
        <PrendreRendezVousForm
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          checkIfAppointmentIsAlreadyTaken={checkIfAppointmentIsAlreadyTaken}
          prestation={props.prestation}
          alreadyClickOnSubmitFormButton={alreadyClickOnSubmitFormButton}
          setAlreadyClickOnSubmitFormButton={setAlreadyClickOnSubmitFormButton}
          successfullySubmitForm={successfullySubmitForm}
          today={formData.today}
          selectedDay={selectedDay}
          rateLimitOK={rateLimitOK}
        />
      ) : (
        <></>
      )}

      {/* {successfullySubmitForm && (
        <div className="form-row successfullySubmitForm">
          Votre prise de rendez-vous à bien été prise en compte !!!
        </div>
      )} */}
    </section>
  );
};

export default PrendreRendezVous;
