import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
// import Tarif from "./pages/Home/Tarif";
// import Temoignage from "./pages/Home/Temoignage";

import Login from "./pages/Login";
import Register from "./pages/Register";
import Navbar from "./components/Navbar";

import React, { useState } from "react";

// Create a context to share the function between the Navbar and the HomePage (and other components)
import { MyContext, ScrollToSection } from "./utils/MyContext";

function App() {
  // State to store the navbar height
  const [navbarHeight, setNavbarHeight] = useState(0);

  // BrowserRouter to have access to the routes
  // Routes to define the routes
  // Route to define a single route
  return (
    <>
      <BrowserRouter>
        <MyContext.Provider
          value={{ navbarHeight, setNavbarHeight, ScrollToSection }}
        >
          <Navbar />

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="register" element={<Register />} />
          </Routes>
        </MyContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
