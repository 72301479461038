import React, { useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import "../styles/Navbar.css";
import logo from "../images/logo.webp";
import { MyContext } from "../utils/MyContext";

const Navbar = () => {
  // help to set the navbar height into the context
  // const { setNavbarHeight } = useContext(MyContext);
  const { navbarHeight, setNavbarHeight, ScrollToSection } =
    useContext(MyContext);

  const navbarRef = useRef(null);

  useEffect(() => {
    if (navbarRef.current) {
      const heightNavbar = navbarRef.current.clientHeight;
      setNavbarHeight(heightNavbar);
    }
  }, [setNavbarHeight]);

  // const scrollToSection = (id) => {
  //   // const navbarHeight = navbarRef.current.clientHeight;
  //   const element = document.getElementById(id);
  //   const offset = -navbarHeight;

  //   if (element) {
  //     window.scrollTo({ top: offset + element.offsetTop, behavior: "smooth" });
  //   }
  // };

  return (
    <nav className="navbar" ref={navbarRef}>
      <Link
        to="/#"
        className="navbar-logo"
        onClick={() => ScrollToSection("carrousel", navbarHeight)}
      >
        <img src={logo} alt="logo" className="logo" />
      </Link>
      <ul className="navbar-list">
        {/* <li className="navbar-item">
          <Link
            to="/#"
            className="navbar-link"
            onClick={() => ScrollToSection("carrousel", navbarHeight)}
          >
            Home
          </Link>
        </li> */}
        <li className="navbar-item">
          <Link
            to="#tarif"
            className="navbar-link"
            onClick={() => ScrollToSection("tarif", navbarHeight)}
          >
            Tarif / Prendre rendez-vous
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
