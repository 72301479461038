import React, { useContext, useState, useRef, useEffect } from "react";
import "../../styles/Tarif.css";
import PrendreRendezVous from "./PrendreRendezVous";

import { MyContext } from "../../utils/MyContext";

const Tarif = ({ setShowIframe }) => {
  const prestation = [
    {
      id: 100,
      group: "beaute_des_mains",
      new_group: "yes",
    },
    {
      id: 2,
      group: "beaute_des_mains",
      value: "Pose de vernis coloré semi-permanent",
      // top: "Top1",
      durationMinutes: 30,
      tarif: 20,
    },
    {
      id: 3,
      group: "beaute_des_mains",
      value: "Soins complets pour les mains",
      durationMinutes: 30,
      tarif: 30,
    },
    {
      id: 4,
      group: "beaute_des_mains",
      value: "Pose de vernis coloré semi-permanent + renforcement par gel",
      durationMinutes: 35,
      tarif: 35,
    },
    {
      id: 44,
      group: "beaute_des_mains",
      value:
        "Pose de capsules avec vernis semi-permanent + renforcement par gel",
      durationMinutes: 50,
      tarif: 40,
    },
    {
      id: 5,
      group: "beaute_des_mains",
      value:
        "Pose de capsules avec vernis semi-permanent + renforcement par résine",
      durationMinutes: 50,
      tarif: 40,
    },
    {
      id: 6,
      group: "beaute_des_mains",
      value: "Remplissage des ongles avec application de gel ou résine",
      durationMinutes: 50,
      tarif: 35,
    },
    {
      id: 7,
      group: "beaute_des_mains",
      value:
        "Pose de chablons avec coloration des ongles + renforcement par gel",
      durationMinutes: 50,
      tarif: 47,
    },
    {
      id: 8,
      group: "beaute_des_mains",
      value:
        "Pose de chablons avec coloration des ongles + renforcement par résine",
      durationMinutes: 50,
      tarif: 47,
    },
    {
      id: 11,
      group: "beaute_des_mains",
      value:
        "Réparation d'un ongle cassé de la main par par technique de remplissage",
      durationMinutes: 10,
      tarif: 3,
    },

    {
      id: 400,
      group: "beaute_des_pieds",
      new_group: "yes",
    },
    {
      id: 49,
      group: "beaute_des_pieds",
      value: "Pose de vernis coloré semi-permanent",
      tarif: 20,
      durationMinutes: 30,
    },
    {
      id: 50,
      group: "beaute_des_pieds",
      value: "Soins complets pour les pieds",
      durationMinutes: 45,
      tarif: 40,
    },
    {
      id: 52,
      group: "beaute_des_pieds",
      value: "Réparation d'un ongle cassé du pied par technique de remplissage",
      durationMinutes: 10,
      tarif: 3,
    },

    {
      id: 1400,
      group: "Supplément_décoratif",
      new_group: "yes",
    },
    {
      id: 119,
      group: "Supplément_décoratif",
      value: "Ajout déco. paillette",
      tarif: 1,
      parDoigt: "yes",
    },
    {
      id: 150,
      group: "Supplément_décoratif",
      value: "Ajout déco. marbre",
      tarif: 1,
      parDoigt: "yes",
    },
    {
      id: 152,
      group: "Supplément_décoratif",
      value: "Ajout déco. d'oeil de chat (Cat eye)",
      tarif: 1,
      parDoigt: "yes",
    },
    {
      id: 155,
      group: "Supplément_décoratif",
      value: "Ajout déco. baby boomer ou french",
      parDoigt: "yes",
      tarif: 1,
    },
    {
      id: 157,
      group: "Supplément_décoratif",
      value: "Ajout déco. dégradé de couleur",
      parDoigt: "yes",
      tarif: 1,
    },
  ];

  // //Access the current height of the navbar
  const { navbarHeight, ScrollToSection } = useContext(MyContext);

  const [selectedPrestation, setSelectedPrestation] = useState("");

  const handleButtonClickPrestation = (prestation) => {
    setSelectedPrestation(prestation);
    ScrollToSection("prendre-rendez-vous", navbarHeight);
  };

  //Add an oberver to start to load the google map when reach tarif section
  const mapRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setShowIframe(true);
        observer.unobserve(mapRef.current);
      }
    });

    if (mapRef.current) {
      observer.observe(mapRef.current);
    }

    return () => {
      if (mapRef.current) {
        observer.unobserve(mapRef.current);
      }
    };
  }, []);

  return (
    <>
      <section id="tarif" data-testid="tarif" ref={mapRef}>
        <h2>Tarif</h2>

        {prestation.map((item, index) => (
          <React.Fragment key={item.id}>
            {item.new_group ? (
              <div className="tarif-item-new-group">
                {item.group.replace("beaute", "Beauté").replaceAll("_", " ")}
              </div>
            ) : (
              // <div></div>
              <div className="tarif-item">
                <div>
                  {item.value}{" "}
                  <span style={{ color: "grey" }}>
                    {!item.parDoigt && (
                      <span>({item.durationMinutes} min)</span>
                    )}
                  </span>
                  {item.top ? (
                    <span className="top-tarif">{item.top}</span>
                  ) : (
                    <></>
                  )}
                </div>

                {item.group !== "Supplément_décoratif" && (
                  <div>
                    <span id="tarif-button-item">
                      <button
                        data-testid="reserver-button"
                        onClick={() => handleButtonClickPrestation(item)}
                        style={{
                          backgroundColor:
                            item.id === selectedPrestation.id
                              ? "#0000ff"
                              : "#ddeffc",
                          color:
                            item.id === selectedPrestation.id
                              ? "rgb(240, 240, 240)"
                              : "#fa4083",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            color:
                              item.id === selectedPrestation.id
                                ? "rgb(255, 255, 255)"
                                : "rgb(0, 0, 0)",
                            fontWeight: 600,
                          }}
                        >
                          &nbsp;
                          {item.tarif}€&nbsp;{item.parDoigt ? "/doigt" : ""}
                        </span>
                        <br />
                        <span style={{ fontWeight: 600 }}>
                          &nbsp;Réserver&nbsp;
                        </span>
                      </button>
                    </span>{" "}
                  </div>
                )}

                {item.group === "Supplément_décoratif" && (
                  <div>
                    <span id="tarif-button-item">
                      <button
                        style={{ backgroundColor: "white", border: "none" }}
                      >
                        <span
                          style={{
                            color: "rgb(0, 0, 0)",
                            fontWeight: 600,
                          }}
                        >
                          &nbsp;
                          {item.tarif}€&nbsp;{item.parDoigt ? "/doigt" : ""}
                        </span>
                        <br />
                      </button>
                    </span>{" "}
                  </div>
                )}
              </div>
            )}
          </React.Fragment>
        ))}
      </section>
      <PrendreRendezVous prestation={selectedPrestation} />
    </>
  );
};

export default Tarif;
