import React, { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import fr from "date-fns/locale/fr";
import axios from "axios";
import API_URL from "../../config/variables-prod";
import "../../styles/PrendreRendezVous.css";
import ReCAPTCHA from "react-google-recaptcha";

const PrendreRendezVousForm = ({
  formData,
  handleChange,
  handleSubmit,
  checkIfAppointmentIsAlreadyTaken,
  prestation,
  alreadyClickOnSubmitFormButton,
  setAlreadyClickOnSubmitFormButton,
  successfullySubmitForm,
  today,
  selectedDay,
  rateLimitOK,
}) => {
  // console.log(formData);

  const [userExists, setUserExists] = useState("");
  const [isCatptchaOK, setIsCatptchaOK] = useState(false);

  const appointmentTimes = [
    // { key: "9", value: "9h", demi: "9h30", heure: "10h" },

    { key: "10", value: "10h", demi: "10h30", heure: "11h" },
    // { key: "10.5", value: "10h30", demi: "11h", heure: "11h30" },
    { key: "11", value: "11h", demi: "11h30", heure: "12h" },
    // { key: "11.5", value: "11h30", demi: "12h", heure: "12h30" },
    { key: "12", value: "12h", demi: "12h30", heure: "13h" },
    // { key: "12.5", value: "12h30", demi: "13h", heure: "13h30" },
    { key: "13", value: "13h", demi: "13h30", heure: "14h" },
    // { key: "13.5", value: "13h30", demi: "14h", heure: "14h30" },
    { key: "14", value: "14h", demi: "14h30", heure: "15h" },
    // { key: "14.5", value: "14h30", demi: "15h", heure: "15h30" },
    { key: "15", value: "15h", demi: "15h30", heure: "16h" },
    // { key: "15.5", value: "15h30", demi: "16h", heure: "16h30" },
    { key: "16", value: "16h", demi: "16h30", heure: "17h" },
    // { key: "16.5", value: "16h30", demi: "17h", heure: "17h30" },
    { key: "17", value: "17h", demi: "17h30", heure: "18" },
    // { key: "17.5", value: "17h30", demi: "18h", heure: "18h30" },
    { key: "18", value: "18h", demi: "18h30", heure: "19h" },
    // { key: "18.5", value: "18h30", demi: "19h", heure: "19h30" },
    { key: "19", value: "19h", demi: "19h30", heure: "20h" },
    // { key: "19.5", value: "19h30", demi: "20h", heure: "20h30" },
    // { key: "20", value: "20h", demi: "20h30", heure: "21h" },
    // { key: "20.5", value: "20h30", demi: "21h", heure: "21h30" },
    // { key: "21", value: "21h", demi: "21h30", heure: "22h" },
  ];

  const handleLoginValidation = (e) => {
    //    console.log("je valideqq");
    //   console.log(formData);

    setAlreadyClickOnSubmitFormButton(true);

    e.preventDefault();

    axios
      .post(API_URL + "/api/v1/login", formData)
      .then((res) => {
        //console.log(res);
        //console.log(res.data);

        if (res.data.exists === true && formData.phoneNumber.length >= 10) {
          setUserExists("true");
        } else if (
          res.data.exists === false &&
          formData.phoneNumber.length >= 10
        ) {
          setUserExists("false");
        } else {
          setUserExists("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //En phase de tests, on considère que le captcha est toujours OK
  //A supprimer plus tard
  useEffect(() => {
    setIsCatptchaOK(true);
  }, []);

  const handleCaptcha = (value) => {
    if (!successfullySubmitForm) {
      if (value) {
        //console.log("Captcha value:", value);
        setIsCatptchaOK(true);
      } else {
        console.log("Captcha not completed or failed");
        setIsCatptchaOK(false);
      }
    }
  };

  const checkIfAppointmentIsSunday = (appointmentDayObject) => {
    // console.log("formData", formData);
    // console.log("appointmentDayObject AA ", appointmentDayObject);

    // console.log("appointmentDayObject XX ", appointmentDayObject.getDate());
    // console.log(
    //   "appointmentDayObject toLocaleDateString ",
    //   appointmentDayObject.toLocaleDateString()
    // );
    return appointmentDayObject.getDay() === 0;
  };

  const notAppointmentBeforeToday = (appointmentDayObject) => {
    const yesterday = new Date();
    yesterday.setDate(new Date().getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    return appointmentDayObject.getTime() < yesterday.getTime();
  };

  const notAppointmentBefore2DaysComing = (appointmentDayObject) => {
    const dayWithin2Days = new Date();
    dayWithin2Days.setDate(new Date().getDate() + 2);
    dayWithin2Days.setHours(0, 0, 0, 0);

    return appointmentDayObject.getTime() < dayWithin2Days.getTime();

    // console.log("formData", formData);
    // return appointmentDayObject.getDay();
  };

  return (
    // <form onSubmit={handleSubmit}>
    <form>
      <h2>Prendre rendez-vous</h2>
      <div className="form-row">
        <div style={{ flex: 1, fontWeight: 600 }}>Choix de prestation:</div>

        <span className="form-row-selected-prestation">
          {prestation.value}
          {prestation.tarif ? " - " : ""}
          {prestation.tarif ? prestation.tarif : ""}
          {prestation.tarif ? " € " : ""}
        </span>
      </div>

      <div className="calendarHeader" style={{ paddingBottom: "5px" }}>
        <span style={{ fontWeight: 700 }}>Cliquez sur une date</span>
        <span> pour choisir le jour du rendez-vous: </span>
      </div>
      <div id="label-choose-day"></div>
      <div className="form-row">
        <div className="DayPicker-offset">
          <DayPicker
            mode="single"
            selected={formData.appointmentDay}
            modifiersClassNames={{
              selected: "my-selected",
            }}
            onDayClick={(e) => handleChange(e, "appointmentDayObject")}
            className="dayPicker"
            locale={fr}
          />
        </div>
      </div>
      <div className="form-row calendarHeader">
        <div id="before-label-appointmentDay">
          {formData.appointmentDayObject && selectedDay === true ? (
            <>
              <span id="label-appointmentDay">Date sélectionnée du rdv: </span>
              <div
                id="synthesize-appointmentDay"
                style={{
                  backgroundColor:
                    !checkIfAppointmentIsSunday(
                      formData.appointmentDayObject
                    ) &&
                    !notAppointmentBefore2DaysComing(
                      formData.appointmentDayObject
                    ) &&
                    !notAppointmentBeforeToday(formData.appointmentDayObject)
                      ? "#0000ffe0"
                      : "white",
                  color:
                    !checkIfAppointmentIsSunday(
                      formData.appointmentDayObject
                    ) &&
                    !notAppointmentBefore2DaysComing(
                      formData.appointmentDayObject
                    ) &&
                    !notAppointmentBeforeToday(formData.appointmentDayObject)
                      ? "white"
                      : "rgb(186 77 95)",
                }}
              >
                {(checkIfAppointmentIsSunday(formData.appointmentDayObject) ||
                  notAppointmentBefore2DaysComing(
                    formData.appointmentDayObject
                  )) &&
                  "⚠ "}
                {/* <i> */}
                {formData.appointmentDayObject &&
                  formData.appointmentDayObject
                    .toLocaleDateString("fr", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                    .replace("lu", "Lu")
                    .replace("mar", "Mar")
                    .replace("mer", "Mer")
                    .replace("je", "Je")
                    .replace("ve", "Ve")
                    .replace("sa", "Sa")
                    .replace("dim", "Dim")}

                {today === formData.appointmentDay ||
                formData.appointmentDay === ""
                  ? " (aujourd'hui)"
                  : ""}

                {checkIfAppointmentIsSunday(formData.appointmentDayObject) &&
                  ". Les rendez-vous le dimanche ne sont pas possibles. Merci de sélectionner un autre jour."}

                {/* {notAppointmentBefore2DaysComing(
                  formData.appointmentDayObject
                ) &&
                  !checkIfAppointmentIsSunday(formData.appointmentDayObject) &&
                  ". Le rendez-vous à cette date n'est pas possible. Afin de bien préparer votre venue, le rendez-vous doit s'effectuer au moins 2 jours à l'avance."} */}
                {/* </i> */}

                {/* {!checkIfAppointmentIsSunday(formData.appointmentDayObject) &&
                  notAppointmentBeforeToday(formData.appointmentDayObject) &&
                  ".  Afin de bien préparer votre venue, le rendez-vous doit s'effectuer au moins 2 jours à l'avance. Merci de sélectionner un autre jour."} */}
                {!checkIfAppointmentIsSunday(formData.appointmentDayObject) &&
                  notAppointmentBeforeToday(formData.appointmentDayObject) &&
                  ".  La date est déja passée. Merci de sélectionner un autre jour."}

                {!checkIfAppointmentIsSunday(formData.appointmentDayObject) &&
                  notAppointmentBefore2DaysComing(
                    formData.appointmentDayObject
                  ) &&
                  !notAppointmentBeforeToday(formData.appointmentDayObject) &&
                  ".  Afin de bien préparer votre venue, je ne prends que les rendez-vous planifiés au moins 2 jours à l'avance. Merci de votre compréhension et de bien vouloir sélectionner un autre jour."}
                {/* </i> */}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="form-row calendarHeader">
        <div>
          {formData.appointmentsAlreadyTaken &&
          selectedDay &&
          !checkIfAppointmentIsSunday(formData.appointmentDayObject) ? (
            <>
              <div id="label-appointmentHour">
                {/* Créneaux de rendez-vous disponibles: */}
                Heure de début du rendez-vous à:
              </div>
              <div id="form-button-hour">
                {appointmentTimes.map((appointmentTime, index) => (
                  <React.Fragment key={index}>
                    {!checkIfAppointmentIsAlreadyTaken(appointmentTime) &&
                      !checkIfAppointmentIsSunday(
                        formData.appointmentDayObject
                      ) &&
                      appointmentTime.key !== "12" &&
                      !notAppointmentBefore2DaysComing(
                        formData.appointmentDayObject
                      ) && (
                        <div
                          onClick={(e) =>
                            handleChange(
                              e,
                              "appointmentHourBegin",
                              appointmentTime
                            )
                          }
                          className="form-button-hour-item"
                          style={{
                            backgroundColor:
                              appointmentTime.value ===
                              formData.appointmentHourBegin
                                ? "#0000ff"
                                : "#ddeffc",
                            color:
                              appointmentTime.value ===
                              formData.appointmentHourBegin
                                ? "rgb(240, 240, 240)"
                                : "rgba(204, 63, 152, 0.866)",
                            fontWeight:
                              appointmentTime.value ===
                              formData.appointmentHourBegin
                                ? "700"
                                : "600",
                            border:
                              appointmentTime.value ===
                              formData.appointmentHourBegin
                                ? "0.7px solid #0000ff"
                                : "0.7px solid rgba(204, 63, 152, 0.866)",
                            // width: "40%",
                          }}
                        >
                          {appointmentTime.value}
                          {/* -{" "} */}
                          {/* {appointmentTime.key.indexOf(".") > 1
                          ? (parseFloat(appointmentTime.key) + 1)
                              .toString()
                              .replace(".5", "h30")
                          : parseFloat(appointmentTime.key) + 1 + "h"} */}
                        </div>
                      )}

                    {checkIfAppointmentIsAlreadyTaken(appointmentTime) ||
                    appointmentTime.key === "12" ||
                    notAppointmentBefore2DaysComing(
                      formData.appointmentDayObject
                    ) ? (
                      <div
                        style={{
                          backgroundColor: "rgba(240, 240, 240, 0.266)",
                          color: "rgba(150, 150, 150, 0.666)",
                          textDecoration: "line-through",
                        }}
                      >
                        {appointmentTime.value} -{" "}
                        {appointmentTime.key.indexOf(".") > 1
                          ? (parseFloat(appointmentTime.key) + 1)
                              .toString()
                              .replace(".5", "h30")
                          : parseFloat(appointmentTime.key) + 1 + "h"}
                      </div>
                    ) : (
                      <></>
                    )}

                    {index % 3 === 2 ? (
                      <>
                        <p style={{ marginTop: "2px" }} abc={index} />
                      </>
                    ) : (
                      <>&nbsp;&nbsp;&nbsp;</>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="form-row calendarHeader">
        <div>
          {formData.appointmentHourBegin &&
          !checkIfAppointmentIsSunday(formData.appointmentDayObject) ? (
            <>
              <span id="label-selectedHour">Résumé du RDV: </span>
              <div id="synthesize-appointment">
                {formData.appointmentDayObject &&
                  formData.appointmentDayObject
                    .toLocaleDateString("fr", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                    .replace("lu", "Lu")
                    .replace("mar", "Mar")
                    .replace("mer", "Mer")
                    .replace("je", "Je")
                    .replace("ve", "Ve")
                    .replace("sa", "Sa")
                    .replace("dim", "Dim")}
                , à {formData.appointmentHourBegin} (
                {/* {formData.appointmentHourBegin} à {formData.selectedHourEnd} ( */}
                {/* {formData.durationMinutes === 60 ? "1 h" : "30 min"}) */}
                pour {formData.durationMinutes} min)
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      {selectedDay === true &&
        formData.appointmentHourBegin &&
        !checkIfAppointmentIsSunday(formData.appointmentDayObject) && (
          <div
            style={{
              borderRadius: "10px",
              borderColor: "#0000ffe0",
              borderWidth: "3px",
              borderStyle: "solid",
              padding: "20px 10px 5px 10px",
              margin: "10px 0",
            }}
          >
            <div className="form-row">
              <span style={{ fontWeight: 300 }}>Je suis: </span>
            </div>
            <div className="form-row" style={{ paddingLeft: "25px" }}>
              <label htmlFor="lastname"> Nom:</label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                value={formData.lastname}
                onChange={(e) => handleChange(e, "lastname")}
                placeholder="Votre nom ici ..."
                readOnly={successfullySubmitForm}
              />
            </div>
            <div className="form-row" style={{ paddingLeft: "25px" }}>
              <label htmlFor="firstname"> Prénom: </label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                value={formData.firstname}
                onChange={(e) => handleChange(e, "firstname")}
                placeholder="Votre prénom ici..."
                readOnly={successfullySubmitForm}
              />
            </div>
            <div className="form-row" style={{ paddingLeft: "25px" }}>
              <label htmlFor="phoneNumber">Téléphone: </label>
              <input
                type="number"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={(e) => handleChange(e, "phoneNumber")}
                placeholder="Votre tél. ici ... (ex. 0612345678)"
                readOnly={successfullySubmitForm}
              />
            </div>
            {/* <div className="form-row">
            <button
              style={{
                width: "200px",
                margin: "0 auto",
                padding: "3px",
                backgroundColor: "rgb(221, 239, 252)",
                borderStyle: "solid",
                borderColor: "rgb(0, 0, 255)",
              }}
              onClick={(e) => handleLoginValidation(e)}
            >
              Je valide mon identité
            </button>
          </div> */}

            {userExists === "true" && rateLimitOK && (
              <>
                <div className="form-row" style={{ textAlign: "center" }}>
                  <p style={{ color: "darkblue" }}>
                    Bonjour {formData.firstname},
                  </p>{" "}
                </div>

                <div className="form-row" style={{ textAlign: "center" }}>
                  <p style={{ color: "blue" }}>
                    c'est un plaisir de veiller à nouveau à votre bien-être !
                  </p>
                </div>
              </>
            )}

            {userExists === "false" && rateLimitOK && (
              <>
                <div
                  className="form-row"
                  style={{ textAlign: "center", color: "darkblue" }}
                >
                  <p>
                    Vous semblez être nouveau ici. Je vous appelerai pour
                    confirmer ce 1er rendez-vous.
                  </p>
                </div>
                <div className="form-row" style={{ textAlign: "center" }}>
                  <p style={{ color: "blue" }}>Merci de votre confiance!</p>
                </div>
              </>
            )}
          </div>
        )}

      {alreadyClickOnSubmitFormButton === true &&
      !successfullySubmitForm &&
      (formData.lastname === "" ||
        formData.firstname === "" ||
        formData.phoneNumber === "" ||
        formData.phoneNumber.length < 10 ||
        formData.appointmentDay === "" ||
        formData.appointmentHourBegin === "") ? (
        <span
          style={{
            color: "#fa4083",
            fontWeight: "bold",
            borderColor: "red",
            borderStyle: "solid",
            borderWidth: "1px",
            padding: "8px 8px",
            backgroundColor: "#efefef",
          }}
        >
          <div className="form-row">
            Les champs suivants sont obligatoires pour la prise de rendez-vous:
          </div>
          <div>
            <ul style={{ listStyleType: "none" }}>
              {formData.appointmentDay === "" ? <li>* Jour</li> : ""}
              {formData.appointmentHourBegin === "" ? (
                <li>* Heure de début du rdv</li>
              ) : (
                ""
              )}
              {formData.lastname === "" ? <li>* Nom</li> : ""}
              {formData.firstname === "" ? <li>* Prénom</li> : ""}
              {formData.phoneNumber === "" ||
              formData.phoneNumber.length < 10 ? (
                <li>* Téléphone (Le numéro renseigné est-il correct ?)</li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </span>
      ) : (
        <></>
      )}

      {selectedDay === true &&
        formData.appointmentHourBegin &&
        !checkIfAppointmentIsSunday(formData.appointmentDayObject) &&
        !successfullySubmitForm && (
          <div className="form-row">
            <div>
              <div style={{ marginTop: "20px", fontWeight: "700" }}>
                <p style={{ fontWeight: "700" }}>
                  ⚠ Pour le challenge de sécurité sur notre site, pourriez-vous
                  confirmer
                </p>
                <p>
                  par le click sur le boutton ci-dessous que vous n'êtes pas un
                  robot:
                </p>
              </div>
              <div style={{ margin: "10px 0 0 10px" }}>
                <ReCAPTCHA
                  sitekey="6Lchv34oAAAAALOTE3whj30wiCncHW0ymyUDgb7E"
                  onChange={handleCaptcha}
                />
              </div>
            </div>
          </div>
        )}

      {selectedDay === true &&
        formData.appointmentHourBegin &&
        isCatptchaOK && (
          <div id="form-button-item">
            {!successfullySubmitForm ? (
              <button
                style={{
                  backgroundColor: "#ddeffc",
                  color: "rgba(204, 63, 152, 0.866)",
                  fontSize: "1.2rem",
                  border: "3.8px solid rgba(204, 63, 152, 0.867)",
                  borderRadius: "15px",
                }}
                onClick={(e) => {
                  handleSubmit(e);
                  handleLoginValidation(e);
                }}
              >
                Cliquez pour confirmer cette prise de rendez-vous
              </button>
            ) : (
              <>
                {rateLimitOK ? (
                  <div
                    style={{
                      color: "rgb(109 205 109)",
                      backgroundColor: "#2e4a4a",
                      borderRadius: "25px",
                      padding: "20px 27px",
                      width: "90%",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                      maxWidth: "650px",
                      margin: "0 auto",
                    }}
                  >
                    Super ! Ton rendez-vous est bien pris en compte ! A très
                    vite
                  </div>
                ) : (
                  <div className="form-button-item">
                    <div
                      style={{
                        color: "rgb(208 56 81)",
                        backgroundColor: "white",
                        borderRadius: "25px",
                        padding: "20px 27px",
                        width: "90%",
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        maxWidth: "650px",
                        margin: "0 auto",
                      }}
                    >
                      ⚠ Tu as tenté plusieurs réservations. Si c'est
                      intentionnel, n'hésite pas à m'appeler par téléphone.
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}

      {/* {rateLimitOK === false && (
        <div className="form-button-item">
          <div
            style={{
              color: "rgb(208 56 81)",
              // backgroundColor: "#2e4a4a",
              borderRadius: "25px",
              padding: "20px 27px",
              width: "90%",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.2rem",
              maxWidth: "650px",
              margin: "0 auto",
            }}
          >
            Tu as tenté plusieurs réservations. Si c'est intentionnel, n'hésite
            pas à m'appeler par téléphone.
          </div>
        </div>
      )} */}
    </form>
  );
};

export default PrendreRendezVousForm;
