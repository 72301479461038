import React from "react";
import "../../styles/Contact.css";
// import { GoogleMap, LoadScript } from "@react-google-maps/api";

const Contact = ({ showIframe }) => {
  return (
    <>
      <section id="contact">
        <h2>Contact</h2>

        <div className="contact-item">
          <div>Téléphone</div>
          <div>
            <span>06 12 86 09 89</span>
          </div>
        </div>

        <div className="contact-item-address">
          <div>Adresse</div>
          <div>
            <p className="adresse-rue">118 boulevard d'Élancourt</p>
            <p className="adresse-ville"> 95280, Jouy-le-Moutier</p>
          </div>
        </div>

        <div className="contact-item">
          {showIframe && (
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d22487.56256495505!2d2.0329865477864164!3d49.0192843341092!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e68b470588e26b%3A0x6427e84f8d288882!2s118%20Bd%20d&#39;%C3%89cancourt%2C%2095280%20Jouy-le-Moutier!5e0!3m2!1sfr!2sfr!4v1687915607818!5m2!1sfr!2sfr"
              height="300"
              loading="lazy"
              title="Plan d'accès à l'onglerie"
            ></iframe>
          )}
        </div>

        <div className="contact-item">
          <div>Horaire</div>
          <div>
            <ul>
              <li className="contact-day">
                <div>
                  <span className="bullet green-bullet"></span> Lundi
                </div>
                <div>10:00 &nbsp;–&nbsp; 20:00</div>
              </li>
              <li className="contact-day">
                <div>
                  <span className="bullet green-bullet"></span> Mardi
                </div>
                <div>10:00 &nbsp;–&nbsp; 20:00</div>
              </li>
              <li className="contact-day">
                <div>
                  <span className="bullet green-bullet"></span> Mercredi
                </div>
                <div>10:00 &nbsp;–&nbsp; 20:00</div>
              </li>
              <li className="contact-day">
                <div>
                  <span className="bullet green-bullet"></span> Jeudi
                </div>
                <div>10:00 &nbsp;–&nbsp; 20:00</div>
              </li>
              <li className="contact-day">
                <div>
                  <span className="bullet green-bullet"></span> Vendredi
                </div>
                <div>10:00 &nbsp;–&nbsp; 20:00</div>
              </li>
              <li className="contact-day">
                <div>
                  <span className="bullet green-bullet"></span> Samedi
                </div>
                <div>10:00 &nbsp;–&nbsp; 20:00</div>
              </li>
              <li className="contact-day red-text">
                <div className="red-color">
                  <span className="bullet red-bullet"></span> Dimanche
                </div>
                <div className="red-color">Fermé</div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section
        id="footer"
        style={{
          textAlign: "right",
          paddingRight: "10px",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        © Thierry Vo, 2023
      </section>
    </>
  );
};

export default Contact;
