import { createContext } from "react";

const MyContext = createContext();

const ScrollToSection = (id, navbarHeight) => {
  const element = document.getElementById(id);
  if (element) {
    window.scrollTo({
      top: -navbarHeight + element.offsetTop,
      behavior: "smooth",
    });
  }
};

export { ScrollToSection, MyContext };
