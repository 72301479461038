import React from "react";
import { useState } from "react";
import "../styles/HomePage.css";

const Register = () => {
  const [name, setName] = useState("");

  const [prenom, setPrenom] = useState("");

  const [telephone, setTelephone] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(`A name was submitted: ${name}`);
  };

  //form handler

  return (
    <form onSubmit={handleSubmit}>
      <h1>Homepage 20230710 form</h1>
      <div>zdsd</div>
      <div>zdazeasd</div>
      <div>zddsqsd</div>

      <div>
        <label htmlFor="nom">
          Nom:
          <input
            type="text"
            id="nom"
            name="nom"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Dupont"
          />
        </label>
      </div>

      <div>
        <label htmlFor="prenom">Prénom: </label>
        <input
          type="text"
          id="prenom"
          name="prenom"
          value={prenom}
          onchange={(e) => setPrenom(e.target.value)}
          placeholder="Sophie"
        />
      </div>
      <div>
        <label htmlFor="telephone">Téléphone: </label>
        <input
          type="text"
          id="telephone"
          name="telephone"
          value={telephone}
          onchange={(e) => setTelephone(e.target.value)}
          placeholder="0612345678"
        />
      </div>

      <button>Valider la prise de rendez-vous</button>
    </form>
  );
};

export default Register;
