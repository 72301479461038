import axios from "axios";
import API_URL from "../../config/variables-prod";

const PrendreRendezVousFormHandleSubmit = (
  formData,
  setSuccessfullySubmitForm,
  setFormData,
  setAlreadyClickOnSubmitFormButton,
  setRateLimitOK
) => {
  // appointmentDay will be the key partition in the dynamoDB table

  if (formData.appointmentDayObject !== null) {
    // console.log(
    //   "debug formData.appointmentDayObject",
    //   formData.appointmentDayObject
    // );

    formData.appointmentDay = formData.appointmentDayObject
      .toLocaleDateString()
      .replaceAll("/", "-");
  }

  const mergeDateAndTime = new Date(formData.appointmentDayObject);

  mergeDateAndTime.setHours(
    formData.appointmentHourBegin.slice(
      0,
      formData.appointmentHourBegin.indexOf("h")
    )
  );

  if (formData.appointmentHourBegin.indexOf("h30") > 1) {
    mergeDateAndTime.setMinutes(30);
  } else {
    mergeDateAndTime.setMinutes(0);
  }

  formData.appointmentTimeBeginISO = mergeDateAndTime.toISOString();
  formData.appointmentTimestampBegin = mergeDateAndTime.getTime();
  formData.appointmentTimestampEnd =
    mergeDateAndTime.getTime() + (formData.durationMinutes - 1) * 60 * 1000;

  // console("debug mergeDateAndTime", mergeDateAndTime);
  formData.appointmentTimeBeginFR = mergeDateAndTime.toLocaleDateString("fr", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  // console("debug mergeDateAndTime", mergeDateAndTime);

  formData.appointmentTimeEndFR = new Date(
    mergeDateAndTime.getTime() + (formData.durationMinutes - 1) * 60 * 1000
  ).toLocaleDateString("fr", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  formData.appointmentTimeEndISO = new Date(
    mergeDateAndTime.getTime() + (formData.durationMinutes - 1) * 60 * 1000
  ).toISOString();

  if (
    formData.lastname !== "" &&
    formData.firstname !== "" &&
    formData.phoneNumber !== "" &&
    formData.appointmentDay !== "" &&
    formData.appointmentHourBegin !== "" &&
    formData.durationMinutes !== 0 &&
    formData.selectedHourEnd !== "" &&
    formData.phoneNumber.length >= 10
  ) {
    axios
      .post(API_URL + "/api/v1/submitAppointmentForm", formData)
      .then((res) => {
        //console.log(res);
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          // Handle rate limit error
          console.log(
            "You've made too many requests. Please wait a few minutes and try again."
          );
          setRateLimitOK(false);
        } else {
          console.log("Error: ", error);
        }
      });
    setSuccessfullySubmitForm(true);

    const appointmentDay = formData.appointmentDay;

    axios
      .get(API_URL + "/api/v1/appointments", { params: { appointmentDay } })
      .then((res) => {
        // console.log("res", res);
        setFormData((prevFormData) => ({
          ...prevFormData,
          appointmentsAlreadyTaken: res.data.appointments.Items,
        }));
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }

  setAlreadyClickOnSubmitFormButton(true);
};

export default PrendreRendezVousFormHandleSubmit;
