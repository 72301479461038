import React, { useContext } from "react";
import "../../styles/Hero.css";
import personneImage from "../../images/personne-jouy-le-mortier-vy6.webp";
import { Link } from "react-router-dom";
import { MyContext } from "../../utils/MyContext";

const Hero = () => {
  // const scrollToSection = (id) => {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const { navbarHeight, ScrollToSection } = useContext(MyContext);

  return (
    <section id="hero">
      <div className="hero-text">
        <h1>
          <span className="h1-text-highlight">Manucure et Pédicure</span>
          <br />
          sur Jouy-le-Moutier (95280), proche de Cergy (95000)
          <br className="" />
          <span className="hero-text-pas-cher">
            Pas cher et de haute qualité
          </span>
        </h1>

        <p>
          Vi Nail est un{" "}
          <span className="text-highlight">bar à ongles à domicile</span>, situé
          à Jouy-le-Moutier (95280), à proximité de Cergy (95000), Courdimanche
          (95800), Andrésy (78570), Maurecourt (78780) et Eragny (95218).
        </p>

        <p>
          Excellente qualité d'onglerie à petit prix, moins cher en moyenne de
          30% que nos concurrents. Fort de 7 ans d'expérience en onglerie, je
          soigne et sublime vos{" "}
          <span className="text-highlight"> ongles de mains et pieds</span>, en
          offrant des prestations de haute qualité à des prix pas cher.
        </p>

        <p>
          <span className="text-highlight">
            Pose classique ou semi-permanent
          </span>
          , optez parmi plus de 200 coloris de la marque de qualité OPI, classé
          parmi les numéro 1 mondiaux.
        </p>

        <p>Ouvert du lundi au samedi de 10h à 20h</p>

        <Link
          to="#contact"
          id="link-prendre-rdv"
          onClick={() => ScrollToSection("tarif", navbarHeight)}
        >
          <button>Prendre rendez-vous</button>
        </Link>
      </div>
      <div id="hero-image">
        <img
          src={personneImage}
          alt="personne Vi"
          // className="hero-image-item"
          // style={{ width: "70%", height: "100%" }}
        />
      </div>
    </section>
  );
};

export default Hero;
