import React from "react";
import "../../styles/Temoignage.css";
import FiveStars from "../../components/FiveStars";

const Temoignage = () => {
  return (
    <>
      <section className="temoignage">
        <h2>Témoignages</h2>
        <div className="temoignage-list">
          <div className="temoignage-item">
            <p>
              " A chaque fois que j'y vais, je suis super contente du résultat
              de ma manucure. Elle est tellement de bonne humeur que nos
              discussions sont super détendues, on peut vraiment parler de
              tout."
            </p>
            <p>
              <FiveStars /> - par Mathilde V.
            </p>
          </div>
          <div className="temoignage-item">
            <p>
              " J'ai essayé d'autres salons pour mes ongles, mais sincèrement,
              les ongles tiennent beaucoup plus longtemps grâce à Vi que dans
              bien d'autres salons de manucure. "
            </p>
            <p>
              <FiveStars /> - par Véronique A.
            </p>
          </div>
          <div className="temoignage-item">
            <p>
              " J'ai été agréablement surprise par la prestation, surtout parce
              que j'étais venue avec un ongle presque cassé, mais elle a réussi
              à tout réparer ! Quelle magicienne ! "
            </p>

            <p>
              <FiveStars /> - par Johanna S.
            </p>
          </div>
          <div className="temoignage-item">
            <p>
              " Absolument parfait ! 13 fois que je viens et à chaque fois, mes
              ongles sont impeccables. Merci infiniment "
            </p>
            <p>
              <FiveStars /> - par Marine B.
            </p>
          </div>
          <div className="temoignage-item">
            <p>
              " L'ambiance est incroyablement plaisante. Vi vous accueille
              chaleureusement et fait preuve d'une efficacité remarquable. Je
              suis totalement enchantée par le résultat de ma manucure et de mon
              soin beauté des pieds ! "
            </p>
            <p>
              <FiveStars /> - par Amanda F.
            </p>
          </div>
          <div className="temoignage-item">
            <p>
              " Le résultat est tout simplement impeccable, et l'hygiène est
              irréprochable ! C'est vraiment un endroit parfait pour moi. J'en
              ai parlé à mes copines, et elles sont également venues chez Vi
              pour se faire poser les ongles"
            </p>
            <p>
              <FiveStars /> - par Fanny F.
            </p>
          </div>
        </div>
        <div id="tarif"></div>
      </section>
    </>
  );
};

export default Temoignage;
