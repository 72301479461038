import React from "react";
import "../styles/Carrousel.css";
import image1 from "../images/nails-manucure.webp";
import image2 from "../images/nails-pedicure.webp";
import image3 from "../images/nails-produitsv2.webp";
import image4 from "../images/nails-manucure2.webp";
import image5 from "../images/nails-manucure5.webp";

const Carrousel = () => {
  return (
    <section id="carrousel">
      <div className="carrousel-list">
        <img
          src={image5}
          alt="beaute"
          style={{ width: "23%", height: "100%" }}
        />
        <img
          src={image1}
          alt="manucure"
          style={{ width: "20%", height: "100%" }}
        />

        <img
          src={image2}
          alt="pedicure"
          style={{ width: "25%", height: "100%" }}
        />
        <img src={image3} alt="opi" style={{ width: "25%", height: "100%" }} />
        <img
          src={image4}
          alt="ongle"
          style={{ width: "27%", height: "100%" }}
        />
        <img
          src={image5}
          alt="beaute"
          style={{ width: "23%", height: "100%" }}
        />
        <img
          src={image1}
          alt="manucure"
          style={{ width: "20%", height: "100%" }}
        />
        <img
          src={image2}
          alt="predicure"
          style={{ width: "23%", height: "100%" }}
        />
        <img src={image3} alt="opi" style={{ width: "25%", height: "100%" }} />
        <img
          src={image4}
          alt="ongle"
          style={{ width: "27%", height: "100%" }}
        />
        <img
          src={image5}
          alt="beaute"
          style={{ width: "23%", height: "100%" }}
        />
        <img
          src={image1}
          alt="manucure"
          style={{ width: "20%", height: "100%" }}
        />
        <img
          src={image2}
          alt="predicure"
          style={{ width: "23%", height: "100%" }}
        />
        <img src={image3} alt="opi" style={{ width: "25%", height: "100%" }} />
        <img
          src={image4}
          alt="ongle"
          style={{ width: "27%", height: "100%" }}
        />
        <img
          src={image5}
          alt="beaute"
          style={{ width: "23%", height: "100%" }}
        />
        <img
          src={image1}
          alt="manucure"
          style={{ width: "20%", height: "100%" }}
        />
        <img
          src={image2}
          alt="predicure"
          style={{ width: "23%", height: "100%" }}
        />
        <img src={image3} alt="opi" style={{ width: "25%", height: "100%" }} />
        <img
          src={image4}
          alt="ongle"
          style={{ width: "27%", height: "100%" }}
        />
        <img
          src={image5}
          alt="beaute"
          style={{ width: "23%", height: "100%" }}
        />
        {/* <img src={image4} alt="ongle" /> */}
      </div>
    </section>
  );
};

export default Carrousel;
