import React, { useState } from "react";

import Hero from "./Hero";
import Tarif from "./Tarif";
import Temoignage from "./Temoignage";
import Contact from "./Contact";
import Carrousel from "../../components/Carrousel";

const HomePage = () => {
  //Add an oberver to start to load the google map when reach tarif section
  const [showIframe, setShowIframe] = useState(false);

  return (
    <>
      <Carrousel />
      <Hero />
      <Temoignage />
      <Tarif setShowIframe={setShowIframe} />
      <Contact showIframe={showIframe} />
    </>
  );
};

export default HomePage;
