import React from "react";

import "../styles/FiveStars.css";

const FiveStars = () => {
  return (
    <span className="FiveStars">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="35"
        viewBox="0 0 25 25"
      >
        <path
          fill="#ffb500"
          fillRule="evenodd"
          d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="35"
        viewBox="0 0 25 25"
      >
        <path
          fill="#ffb500"
          fillRule="evenodd"
          d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="35"
        viewBox="0 0 25 25"
      >
        <path
          fill="#ffb500"
          fillRule="evenodd"
          d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="35"
        viewBox="0 0 25 25"
      >
        <path
          fill="#ffb500"
          fillRule="evenodd"
          d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="35"
        viewBox="0 0 25 25"
      >
        <path
          fill="#ffb500"
          fillRule="evenodd"
          d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"
        ></path>
      </svg>
    </span>
  );
};

export default FiveStars;
